.coreAnalysisEfficiency {
  position: absolute;
  top: 47px;
  left: 136px;
  font-size: var(--font-size-13xl);
  font-family: var(--font-inter);
  color: var(--color-gray-300);
}
.vectorIcon {
  position: absolute;
  height: 1.83%;
  width: 1.3%;
  top: 5.18%;
  right: 29.81%;
  bottom: 93%;
  left: 68.89%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 53.5px;
  left: 1026px;
  font-family: var(--font-inter);
  color: var(--color-darkgray);
}
.coreAnalysisEfficiencySectoChild {
  position: absolute;
  top: 46px;
  left: 1205px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.rushanArshad {
  position: absolute;
  top: 58px;
  left: 1252px;
  font-family: var(--font-inter);
}
.bxbxChevronDownIcon,
.userPlus2Icon {
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.bxbxChevronDownIcon {
  position: absolute;
  top: 58px;
  left: 1372px;
}
.userPlus2Icon {
  position: relative;
  flex-shrink: 0;
  display: none;
}
.button1 {
  position: relative;
  line-height: 24px;
}
.button {
  position: absolute;
  top: 812px;
  left: 147px;
  border-radius: var(--br-7xl);
  background-color: var(--color-yellowgreen-200);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  cursor: pointer;
  color: var(--neutral-white);
}
.efficiencyCoreTab,
.efficiencyCoreTabChild {
  position: absolute;
  background-color: var(--color-whitesmoke-100);
}
.efficiencyCoreTabChild {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.efficiencyCoreTab {
  top: 145px;
  left: 173px;
  width: 1060px;
  height: 65px;
}
.button10,
.button14,
.button18,
.button2,
.button22,
.button26,
.button30,
.button34,
.button6 {
  position: absolute;
  top: 151px;
  left: 180px;
  border-radius: var(--br-7xl);
  background-color: var(--neutral-white);
  width: 115px;
  height: 52px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
}
.button10,
.button14,
.button18,
.button22,
.button26,
.button30,
.button34,
.button6 {
  left: 1104px;
}
.button10,
.button14,
.button18,
.button22,
.button26,
.button30,
.button34 {
  left: 987px;
}
.button14,
.button18,
.button22,
.button26,
.button30,
.button34 {
  left: 872px;
}
.button18,
.button22,
.button26,
.button30,
.button34 {
  left: 757px;
}
.button22,
.button26,
.button30,
.button34 {
  left: 640px;
}
.button26,
.button30,
.button34 {
  left: 525px;
}
.button30,
.button34 {
  left: 410px;
}
.button34 {
  left: 295px;
  background-color: var(--color-orange);
}
.text {
  flex: 1;
  position: relative;
  line-height: 130%;
}
.content {
  align-self: stretch;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
}
.cell,
.content,
.row,
.table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cell {
  align-self: stretch;
  background-color: var(--color-gray-600);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.row,
.table {
  overflow: hidden;
}
.row {
  align-self: stretch;
  background-color: var(--color-gray-600);
  flex-direction: row;
}
.table {
  border-radius: var(--br-9xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-silver-200);
  flex-direction: column;
}
.dateRange,
.table1 {
  position: absolute;
  font-family: var(--font-inter);
}
.table1 {
  top: 536px;
  left: 235px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-xs);
}
.dateRange {
  top: calc(50% - 139.53px);
  left: calc(50% - 415.54px);
  font-size: var(--font-size-5xl);
  line-height: 28px;
  text-align: center;
  display: inline-block;
  width: 145.45px;
  height: 36.76px;
}
.coreAnalysisEfficiencySectoItem {
  position: absolute;
  top: 362.98px;
  left: 736.55px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 173.05px;
  height: 55.73px;
}
.div {
  position: absolute;
  top: 377px;
  left: 779px;
  line-height: 24px;
}
.coreAnalysisEfficiencySectoInner {
  position: absolute;
  top: 363px;
  left: 987px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 183.67px;
  height: 55.73px;
}
.div1 {
  position: absolute;
  top: 376px;
  left: 1032px;
  line-height: 24px;
}
.sector,
.to {
  position: absolute;
  font-size: var(--font-size-5xl);
  line-height: 28px;
  font-family: var(--font-inter);
  text-align: center;
  display: inline-block;
  height: 36.76px;
}
.to {
  top: 371.28px;
  left: 922.34px;
  width: 30.79px;
}
.sector {
  top: calc(50% - 218.98px);
  left: calc(50% - 415.54px);
  width: 102.98px;
}
.rectangleDiv {
  position: absolute;
  top: 274.04px;
  left: 736.55px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 423.6px;
  height: 56.92px;
}
.telecommunications {
  position: absolute;
  top: 295px;
  left: 833px;
  font-size: var(--font-size-xl);
  line-height: 130%;
  font-family: var(--font-inter);
}
.button38,
.groupIcon {
  position: absolute;
  cursor: pointer;
}
.groupIcon {
  bottom: 708.85px;
  left: 1123.8px;
  width: 22.03px;
  height: 21.84px;
}
.button38 {
  top: 686px;
  left: 954px;
  border-radius: var(--br-7xl);
  background-color: var(--color-orange);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  color: var(--neutral-white);
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 0;
  left: 11px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.coreAnalysisEfficiencySecto {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-source-sans-pro);
}
