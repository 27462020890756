.activityStudyBenchmarking {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}

.dropdownsContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  margin-left: 200px;
  gap: 7px;
  margin-top: 240px;
  height: 40px;
  
}
.dropdown {
  width: 135px;
  font-size: 16px;
  color: #333;
  border: 1px solid #a2c73b;
  border-radius: 5px;
  padding: 5px;
  white-space: nowrap;
    /* let the height auto adjust based on content */
}
.dropdown:hover {
  border-color:  #a2c73b;
}
.dropdownCompetitor {
  white-space: nowrap;
  width: 190px;
  font-size: 16px;
  color: #333;
  border: 1px solid #a2c73b;
  border-radius: 5px;
  padding: 5px;
    /* let the height auto adjust based on content */
  max-height: 300px;  /* set a maximum height */
  /* add a scrollbar if the content is taller than the maximum height */
}
.dropdownCompetitor:hover {
  border-color:  #a2c73b;
}


.dateandClear{
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  justify-content: right;
  margin-right: 200px;
  gap: 7px;
  margin-top: -40px;
  height: 40px;
}


/* BenchmarkingEfficiency.module.css */
.dateInputContainer {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
  height: 38px;
  padding: 5px;
  border: 1px solid #a2c73b;
}

.dateInput {
  width: 180px; /* Matched with dropdown width */
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  height: 40px;
  
}
.clearButton {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #a2c73b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background 0.5s ease;
  height:38px;
}
.errorMessageBox {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #a2c73b; /* Updated border color */
  text-align: center;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.errorMessage {
  color: 'Black';
  font-weight: bold;
}

.closeErrorButton {
  background-color: #a2c73b;
  color: white;
  border: none;
  border-radius: 50px; /* Updated to make the button oval */
  padding: 5px 20px; 
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}
.loadingContainer {
  position: fixed; /* Position the loader independently of other elements */
  top: 50%; /* Position halfway down the viewport */
  left: 50%; /* Position halfway across the viewport */
  transform: translate(-50%, -50%); /* Offset the loader's own dimensions */
  z-index: 9999; /* Ensure the loader is above all other elements */
}
.bxbxSearchIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.bxbxSearchIcon,
.search {
  position: relative;
}
.bxbxSearchParent {
  position: absolute;
  top: 29px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--color-darkgray);
}
.topBarBenchmarkingChild {
  position: absolute;
  top: 32px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.rushanArshad {
  position: absolute;
  top: 44px;
  left: 1136px;
  color: var(--color-black);
}
.bxbxChevronDownIcon {
  position: absolute;
  top: 44px;
  left: 1256px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.task,
.topBarBenchmarking {
  position: absolute;
  text-align: left;
}
.topBarBenchmarking {
  top: 14px;
  left: 116px;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
  overflow: hidden;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
}
.task {
  top: 643px;
  left: 176px;
  line-height: 130%;
}
.customer,
.dateRange,
.industry {
  position: absolute;
  top: calc(50% - 242px);
  left: calc(50% - 543px);
  line-height: 28px;
}
.dateRange,
.industry {
  top: calc(50% - 178px);
}
.industry {
  top: calc(50% - 115px);
}
.controllingElement,
.element,
.mainCategory,
.sector,
.subCategory {
  position: absolute;
  top: calc(50% - 52px);
  left: calc(50% - 543px);
  line-height: 28px;
}
.controllingElement,
.element,
.mainCategory,
.subCategory {
  top: calc(50% + 12px);
}
.controllingElement,
.element,
.subCategory {
  top: calc(50% + 75px);
}
.controllingElement,
.element {
  top: calc(50% + 202px);
}
.controllingElement {
  top: calc(50% + 261px);
  left: calc(50% - 546px);
}
.benchmarkingActivityChild,
.benchmarkingActivityInner,
.benchmarkingActivityItem {
  position: absolute;
  top: 264px;
  left: 477px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 385px;
  height: 44px;
}
.benchmarkingActivityInner,
.benchmarkingActivityItem {
  top: 701px;
}
.benchmarkingActivityInner {
  top: 760px;
  left: 476px;
}
.benchmarkingActivityChild1,
.benchmarkingActivityChild2,
.benchmarkingActivityChild3,
.benchmarkingActivityChild4,
.benchmarkingActivityChild5,
.rectangleDiv {
  position: absolute;
  top: 631px;
  left: 477px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 385px;
  height: 44px;
}
.benchmarkingActivityChild1,
.benchmarkingActivityChild2,
.benchmarkingActivityChild3,
.benchmarkingActivityChild4,
.benchmarkingActivityChild5 {
  top: 504px;
}
.benchmarkingActivityChild2,
.benchmarkingActivityChild3,
.benchmarkingActivityChild4,
.benchmarkingActivityChild5 {
  top: 567px;
}
.benchmarkingActivityChild3,
.benchmarkingActivityChild4,
.benchmarkingActivityChild5 {
  top: 441px;
}
.benchmarkingActivityChild4,
.benchmarkingActivityChild5 {
  top: 384px;
}
.benchmarkingActivityChild5 {
  top: 321px;
  width: 156px;
}
.to {
  position: absolute;
  top: 330px;
  left: 649px;
  line-height: 28px;
}
.benchmarkingActivityChild6 {
  position: absolute;
  top: 321px;
  left: 701px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 161px;
  height: 44px;
}
.text {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.content {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
}
.cell,
.content,
.row {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cell {
  background-color: var(--color-gray-400);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.row {
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-direction: row;
  font-size: var(--font-size-xl);
}
.text4 {
  flex: 1;
  position: relative;
  line-height: 130%;
}
.cell4 {
  align-self: stretch;
  background-color: var(--color-gray-600);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.benchmarkingResults,
.cell4,
.row1,
.table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.row1 {
  align-self: stretch;
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-direction: row;
}
.benchmarkingResults,
.table {
  flex-direction: column;
}
.table {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-silver-200);
  overflow: hidden;
}
.benchmarkingResults {
  position: absolute;
  top: 346px;
  left: 897px;
  width: 480px;
  text-align: left;
  font-size: var(--font-size-xs);
}
.userPlus2Icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button1 {
  position: relative;
  line-height: 24px;
}
.button,
.button2 {
  position: absolute;
  border-radius: var(--br-7xl);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
.button {
  top: 863px;
  left: 596px;
  background-color: #3aa0f2;
}
.button2 {
  top: 872px;
  left: 174px;
  background-color: var(--color-yellowgreen-200);
  cursor: pointer;
}
.menubenchmarkingstudies {
  position: absolute;
  top: 130px;
  left: 149px;
  border-radius: var(--br-41xl);
  background-color: var(--neutral-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 1376px;
  height: 85px;
}
.vectorIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.efficiencyStudy {
  position: absolute;
  top: 31.25%;
  left: 22.49%;
  line-height: 24px;
}
.buttongeneralvariant4 {
  position: absolute;
  top: 142px;
  left: 204px;
  width: 209px;
  height: 64px;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
.activityStudy {
  position: absolute;
  top: 31.25%;
  left: 29.67%;
  line-height: 24px;
}
.buttongeneralvariant5,
.buttongeneralvariant6 {
  position: absolute;
  top: 142px;
  left: 440px;
  width: 209px;
  height: 64px;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
.buttongeneralvariant6 {
  left: 681px;
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 0;
  left: 6px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.benchmarkingActivity {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-inter);
}
