.generaltextfieldIcon,
.generaltextfieldIcon1,
.logoIcon {
  position: absolute;
  height: 6.41%;
  width: 9.09%;
  top: 8.7%;
  right: 28.54%;
  bottom: 84.89%;
  left: 28.37%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.generaltextfieldIcon,
.generaltextfieldIcon1 {
  height: 6.32%;
  width: 25.0%;
  top: 18.94%;
  right: 29.12%;
  bottom: 71.74%;
  left: 20.49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.generaltextfieldIcon1 {
  top: 30.75%;
  bottom: 59.94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.email {
  position: relative;
  top: 9.57%;
  left: 23.42%;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.password {
  position: relative;
  top: 31.21%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vectorIcon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error {
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success {
  color: green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login {
  position: absolute;
  width: 19.14%;
  top: 31.25%;
  left: 43.0%;
  font-size: var(--font-size-base);
  line-height: 24px;
  display: flex;
  font-family: var(--font-source-sans-pro);
  color: var(--neutral-white);
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttongeneralvariant4 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 9.94%;
  width: 20.13%;
  top: 47.67%;
  right: 34.21%;
  bottom: 42.39%;
  left: 25.0%;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forgotPassword {
  position: absolute;
  top: 40.06%;
  left: 37.40%;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-yellowgreen-200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginwindow {
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--neutral-white);
  width: 80%;
  height: 644px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-source-sans-pro);
  left:22.5%;
  
  /* Added properties */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* This is to organize child elements vertically */

}
.centered {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center;
  align-items: center;
  width: 80%; /* Adjust this to control the width of the content */
  max-width: 500px; /* Prevents the content from being too wide on large screens */
  margin: 0 auto; /* Center the content horizontally */
}

.fieldContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; /* adjust this value to increase or decrease spacing */
}
.formContainer {
  display: flex;
  position: absolute;
  top: 215px;
  left: 379px;
  box-shadow: var(--long);
  width: 300px;
  height: 180px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  text-align: center;
  color: var(--color-yellowgreen-100);
  font-family: var(--font-source-sans-pro);
  flex-direction: column;
  text-align: center;
}

.formLabel {
  margin: 10px 0;
}

.formLabel, .formInput {
  width: 100%; /* make each label and input take up the full width of the form */
}

.formInput {
  /* width: 200px; */ /* Commented out this line */
  height: 30px;
  padding: 5px;
  margin-bottom: 10px;
}

.addUser1 {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
  font-family: var(--font-inter);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.addButton {
  width: 100px;
  height: 30px;
  background-color: #a2c73b;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 10px 30px;
  cursor: pointer;
}

.addButton:hover {
  background-color: #8ab029;
}

