.coreAnalysisEfficiency {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}
.bxbxSearchIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.bxbxSearchIcon,
.search {
  position: relative;
}
.bxbxSearchParent {
  position: absolute;
  top: 29px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--color-darkgray);
}
.topBarBenchmarkingChild {
  position: absolute;
  top: 32px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.rushanArshad {
  position: absolute;
  top: 44px;
  left: 1136px;
  color: var(--color-black);
}
.bxbxChevronDownIcon,
.topBarBenchmarking {
  position: absolute;
  top: 44px;
  left: 1256px;
  width: 24px;
  height: 24px;
  overflow: hidden;
}
.topBarBenchmarking {
  top: 14px;
  left: 116px;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
  color: var(--color-gray-300);
  font-family: var(--font-inter);
}
.userPlus2Icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button1 {
  position: relative;
  line-height: 24px;
}
.button {
  position: absolute;
  top: 812px;
  left: 147px;
  border-radius: var(--br-7xl);
  background-color: var(--color-yellowgreen-200);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  cursor: pointer;
}
.text {
  flex: 1;
  position: relative;
  line-height: 130%;
}
.cell,
.content {
  align-self: stretch;
}
.content {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.cell {
  background-color: var(--color-gray-600);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.cell,
.row,
.table,
.table2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.row {
  align-self: stretch;
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-direction: row;
}
.table,
.table2 {
  flex-direction: column;
}
.table {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-silver-200);
  overflow: hidden;
}
.table2 {
  position: absolute;
  top: 318px;
  left: 59px;
  width: 600px;
  font-size: var(--font-size-xs);
}
.dateRange {
  position: absolute;
  top: calc(50% - 130.03px);
  left: calc(50% - 380.04px);
  line-height: 28px;
  text-align: center;
  display: inline-block;
  width: 145.45px;
  height: 36.76px;
}
.analysisContentAreaChild {
  position: absolute;
  top: 144.98px;
  left: 560.55px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 173.05px;
  height: 55.73px;
}
.div {
  position: absolute;
  top: 159px;
  left: 603px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-source-sans-pro);
}
.analysisContentAreaItem {
  position: absolute;
  top: 144.98px;
  left: 800.48px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 183.67px;
  height: 55.73px;
}
.div1 {
  position: absolute;
  top: 158px;
  left: 856px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-source-sans-pro);
}
.sector,
.to {
  position: absolute;
  line-height: 28px;
  text-align: center;
  display: inline-block;
  height: 36.76px;
}
.to {
  top: 153.28px;
  left: 746.34px;
  width: 30.79px;
}
.sector {
  top: calc(50% - 209.48px);
  left: calc(50% - 380.04px);
  width: 102.98px;
}
.analysisContentAreaInner {
  position: absolute;
  top: 56.04px;
  left: 560.55px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 423.6px;
  height: 56.92px;
}
.telecommunications {
  position: absolute;
  top: 71px;
  left: 659px;
  font-size: var(--font-size-xl);
  line-height: 130%;
}
.groupIcon {
  position: absolute;
  bottom: 471.85px;
  left: 947.8px;
  width: 22.03px;
  height: 21.84px;
  cursor: pointer;
}
.analysisContentArea,
.button2 {
  position: absolute;
  box-sizing: border-box;
}
.button2 {
  top: 468px;
  left: 778px;
  border-radius: var(--br-7xl);
  background-color: var(--color-orange);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  cursor: pointer;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
.analysisContentArea {
  top: 217.5px;
  left: 178.5px;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  border: 1px dashed var(--color-silver-100);
  width: 1017px;
  height: 569px;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 0;
  left: 7px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.vectorIcon {
  position: absolute;
  height: 2.34%;
  width: 1.67%;
  top: 15.04%;
  right: 85%;
  bottom: 82.62%;
  left: 13.33%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  cursor: pointer;
}
.menubenchmarkingstudies {
  position: absolute;
  top: 132px;
  left: 172px;
  border-radius: var(--br-41xl);
  background-color: var(--neutral-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 734px;
  height: 60px;
}
.vectorIcon1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.efficiencyStudy {
  position: absolute;
  top: 19.35%;
  left: 22.49%;
  line-height: 24px;
  display: none;
}
.buttongeneralvariant4,
.buttongeneralvariant42,
.buttongeneralvariant44,
.buttongeneralvariant46 {
  position: absolute;
  top: 141px;
  left: 187px;
  width: 87px;
  height: 42px;
  overflow: hidden;
  cursor: pointer;
}
.buttongeneralvariant42,
.buttongeneralvariant44,
.buttongeneralvariant46 {
  left: 274px;
}
.buttongeneralvariant44,
.buttongeneralvariant46 {
  left: 361px;
}
.buttongeneralvariant46 {
  left: 448px;
}
.buttongeneralvariant410,
.buttongeneralvariant412,
.buttongeneralvariant414,
.buttongeneralvariant48 {
  position: absolute;
  top: 141px;
  left: 535px;
  width: 87px;
  height: 42px;
  overflow: hidden;
  cursor: pointer;
}
.buttongeneralvariant410,
.buttongeneralvariant412,
.buttongeneralvariant414 {
  left: 622px;
}
.buttongeneralvariant412,
.buttongeneralvariant414 {
  left: 709px;
}
.buttongeneralvariant414 {
  left: 797px;
}
.area,
.category,
.customer,
.element,
.industry,
.sector1,
.task,
.timeOfDay {
  position: absolute;
  top: 149px;
  left: 201px;
  line-height: 24px;
  color: var(--color-black);
}
.area,
.category,
.customer,
.element,
.sector1,
.task,
.timeOfDay {
  left: 288px;
}
.area,
.category,
.customer,
.element,
.task,
.timeOfDay {
  left: 382px;
}
.category,
.customer,
.element,
.task,
.timeOfDay {
  left: 460px;
}
.customer,
.element,
.task,
.timeOfDay {
  left: 547px;
}
.customer,
.task,
.timeOfDay {
  left: 647px;
}
.customer,
.timeOfDay {
  left: 712px;
}
.customer {
  top: 148px;
  left: 807px;
}
.coreAnalysisEfficiencySecto {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
