.setSomeFilters {
  position: absolute;
  top: 198px;
  left: 0;
  line-height: 24px;
}
.filterIcon {
  position: absolute;
  top: 0;
  left: 71px;
  width: 177px;
  height: 177px;
  overflow: hidden;
}
.frameChild {
  position: absolute;
  top: 118px;
  left: 156px;
  width: 70px;
  height: 70px;
}
.setSomeFiltersToGetReportParent {
  position: absolute;
  top: 384px;
  left: 576px;
  width: 340px;
  height: 222px;
  text-align: center;
}
.userPlus2Icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button1 {
  position: relative;
  line-height: 24px;
}
.button,
.pageTitle {
  flex-direction: row;
  align-items: center;
}
.button {
  border-radius: var(--br-7xl);
  background-color: var(--color-yellowgreen-100);
  display: none;
  padding: var(--padding-5xs) var(--padding-xl);
  justify-content: center;
  gap: var(--gap-7xs);
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
.pageTitle {
  position: absolute;
  top: 121px;
  left: 111px;
  width: 1301px;
  display: flex;
  justify-content: flex-start;
  gap: 1017px;
  color: var(--color-darkslategray);
}
.label {
  position: relative;
}
.dropdown {
  align-self: stretch;
  border-radius: var(--br-7xl);
  background-color: var(--neutral-white);
  border: 1px solid ;
  border-color: #a2c73b;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs) var(--padding-3xs)
    var(--padding-xl);
  align-items: center;
  justify-content: space-between;
  color: var(--color-gray-100);
}
.errorField {
  position: relative;
  font-size: var(--font-size-sm);
  color: var(--color-crimson);
  opacity: 0;
}
.inputlabel {
  width: 240.02px;
  height: 86px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.dropdown1 {
  align-self: stretch;
  border-radius: var(--br-7xl);
  background-color: var(--color-whitesmoke-100);
  border: 1px solid;
  border-color: #a2c73b;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs) var(--padding-3xs)
    var(--padding-xl);
  align-items: center;
  justify-content: space-between;
  color: var(--color-silver-100);
}
.inputlabel2 {
  flex: 1;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.studyDateRange {
  position: relative;
  display: flex;
  align-items: center;
  width: 118.96px;
  border-color: #a2c73b;
}
.bigBan,
.from1 {
  position: absolute;
  line-height: 12.5px;
  border-color: #a2c73b;
}
.from1 {
  top: 0.56px;
  left: 0;
  text-transform: uppercase;
}
.bigBan {
  top: 0;
  left: 34.25px;
  font-size: var(--font-size-base);
}
.fromParent {
  position: relative;
  width: 85.25px;
  height: 13.56px;
}
.from {
  border-radius: var(--br-7xl) 0 0 var(--br-7xl);
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 190.25px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs)
    var(--padding-xl);
  align-items: center;
  justify-content: space-between;
}
.now {
  position: absolute;
  top: 0;
  left: 19px;
  font-size: var(--font-size-base);
  line-height: 12.5px;
}
.toParent {
  position: relative;
  width: 50px;
  height: 13.56px;
}
.to {
  border-radius: 0 var(--br-7xl) var(--br-7xl) 0;
  border: 1px solid var(--color-silver-100);
  box-sizing: border-box;
  width: 161px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) var(--padding-3xs)
    var(--padding-xl);
  align-items: center;
  justify-content: space-between;
}
.buttonGroup,
.dateRange,
.inputlabelParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-color: #a2c73b;
}
.buttonGroup {
  flex-direction: row;
  font-size: var(--font-size-xs);
  color: var(--color-silver-100);
}
.dateRange,
.inputlabelParent {
  flex-direction: column;
  gap: var(--gap-9xs);
 
}
.dateRange {
  border-color: #a2c73b;
}
.inputlabelParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-2xl);
}
.link {
  position: relative;
  text-decoration: underline;
  line-height: 24px;
  font-weight: 600;
}
.button2,
.buttonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button2 {
  border-radius: var(--br-3xs);
  justify-content: center;
  cursor: pointer;
}
.buttonWrapper {
  align-self: stretch;
  justify-content: space-between;
  color: var(--color-silver-100);
}
.filters,
.universalsidebar1 {
  position: absolute;
  background-color: var(--neutral-white);
}
.filters {
  top: 165px;
  left: 104px;
  border-radius: var(--br-3xs);
  width: 1267px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-source-sans-pro);
}
.universalsidebar1 {
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 6px;
  left: 5px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.topBarText {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}
.topBarChild {
  position: absolute;
  top: 25px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
}
.vectorIcon {
  position: absolute;
  height: 17.99%;
  width: 1.44%;
  top: 33.65%;
  right: 30.91%;
  bottom: 48.36%;
  left: 67.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 35px;
  left: 910px;
  color: var(--color-darkgray);
}
.topBarItem {
  position: absolute;
  top: 23px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.loggedUser {
  position: absolute;
  top: 34px;
  left: 1136px;
  font-family: var(--font-source-sans-pro);
  color: var(--color-black);
}
.topBar,
.vectorIcon1 {
  position: absolute;
  overflow: hidden;
}
.vectorIcon1 {
  height: 6.85%;
  width: 0.88%;
  top: 38.74%;
  right: 1.41%;
  bottom: 54.41%;
  left: 97.71%;
  max-width: 100%;
  max-height: 100%;
}
.topBar {
  top: 6px;
  left: 94px;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
}
.projects {
  position: relative;
  background-color: white;
  width: 100%;
  height: 875px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-5xl);
  color:white;
  font-family: var(--font-inter);
}
