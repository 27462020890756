.dropdownsContainer {
    display: flex;
    width:auto;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    margin-left: 100px;
    gap: 7px;
    margin-top: 240px;
    height: auto;
    
  }
  .dropdown {
    width:auto;
    font-size: 12px;
    color: #333;
    border: 1px solid #a2c73b;
    border-radius: 5px;
    padding: 5px;
    white-space: normal; /* Allow text to wrap to the next line */
    height:auto;
  }
  
  .dropdown:hover {
    border-color:  #a2c73b;
  }
  .competitorsContainer{
    width:auto;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
    margin-top: 60px;
    height: auto;
  }
  
  .dropdownCompetitor {
    white-space: nowrap;
    width:auto;
    white-space: normal; /* Allow text to wrap to the next line */
    font-size: 12px;
    color: #333;
    border: 1px solid #a2c73b;
    border-radius: 5px;
    padding: 5px;
      /* let the height auto adjust based on content */
    max-height: 300px;  /* set a maximum height */
    /* add a scrollbar if the content is taller than the maximum height */
  }
  .dropdownCompetitor:hover {
    border-color:  #a2c73b;
  }
  
  .dateandClear{
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-start;
    justify-content: right;
    margin-right: 200px;
    gap: 7px;
    margin-top: -40px;
    height: 40px;
  }
  
  
  /* BenchmarkingEfficiency.module.css */
  .dateInputContainer {
    display: flex;
    justify-content: right;
    margin-bottom: 20px;
    height: 38px;
    padding: 5px;
    border: 1px solid #a2c73b;
  }
  
  .dateInput {
    width: 180px; /* Matched with dropdown width */
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 40px;
    
  }
  
  
  .chartContainer {
    white-space: nowrap;
    display: flex;
    justify-content: flex-start; /* Aligns charts to the left */
    align-items: center; /* Centers the charts vertically */
    flex-wrap: nowrap; /* Prevents wrapping onto the next line */
    width: 805; /* Makes the container span the full width */
    padding: 10px; /* Adjusts padding */
    overflow-x:auto; /* Allows scrolling if content overflows */
    margin-top: -25px; /* Adds space between charts and clear button */
    margin-left: 60px; /* Adds space between charts and clear button */
    background: transparent; /* Removes background color */;
  }
  
  .chart {
    white-space: nowrap;
    max-width: 200px; /* adjust this as needed, include the unit 'px' */
    height: 80%;
    margin-right: 0px; /* Adds space between charts */
  }
  
  /* CSS Styling for Clear Button */
  .clearButton {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 0px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #a2c73b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background 0.5s ease;
    height:38px;
  }
  
  .errorMessageBox {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #a2c73b; /* Updated border color */
    text-align: center;
    width: 300px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .errorMessage {
    color: 'Black';
    font-weight: bold;
  }
  
  .closeErrorButton {
    background-color: #a2c73b;
    color: white;
    border: none;
    border-radius: 50px; /* Updated to make the button oval */
    padding: 5px 20px; 
    cursor: pointer;
    font-weight: bold;
    margin-top: 10px;
  }
  
  
  .clearButton:hover {
    background-color: #85a029;
  }
  
  .clearButton:active {
    background-color: #6f8920;
  }
  
  @media screen and (max-width: 480px) {
    .clearButton {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  /* CSS Styling for CheckBoxes */
  .checkboxContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    margin-top: 60px;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    margin-left: 200px;
   
    
    border-radius: 5px;
  }
  .loadingContainer {
    position: fixed; /* Position the loader independently of other elements */
    top: 50%; /* Position halfway down the viewport */
    left: 50%; /* Position halfway across the viewport */
    transform: translate(-50%, -50%); /* Offset the loader's own dimensions */
    z-index: 9999; /* Ensure the loader is above all other elements */
  }
  
  
  
  
  
  .checkboxContainer label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkboxContainer input[type="checkbox"] {
    margin-right: 10px;
  }
  
  
  
  
  
  
  .polygonIcon {
    position: absolute;
    bottom: 542px;
    left: 981.34px;
    width: 17.32px;
    height: 12.75px;
  }
  .universalsidebar1 {
    position: absolute;
    height: 95.45%;
    width: 102.38%;
    top: 0;
    right: -5.95%;
    bottom: 4.55%;
    left: 3.57%;
    background-color: var(--neutral-white);
  }
  .homeIcon,
  .homeIcon1,
  .logoIcon {
    position: absolute;
    height: 3.89%;
    width: 28.57%;
    top: 11.99%;
    right: 29.76%;
    bottom: 84.12%;
    left: 41.67%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .homeIcon1,
  .logoIcon {
    height: 6.95%;
    width: 70.24%;
    top: 1.01%;
    right: 16.67%;
    bottom: 92.04%;
    left: 13.1%;
  }
  .homeIcon1 {
    height: 4.04%;
    width: 28.57%;
    top: 15.82%;
    right: 30.95%;
    bottom: 80.13%;
    left: 40.48%;
    cursor: pointer;
  }
  .projectsIcon {
    top: 22.05%;
    right: 10.71%;
    bottom: 67.85%;
    left: 17.86%;
    cursor: pointer;
  }
  .analysisIcon,
  .projectsIcon,
  .settingsIcon,
  .usersIcon {
    position: absolute;
    height: 10.1%;
    width: 71.43%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .usersIcon {
    top: 30.98%;
    right: 9.52%;
    bottom: 58.92%;
    left: 19.05%;
    cursor: pointer;
  }
  .analysisIcon,
  .settingsIcon {
    right: 10.71%;
    left: 17.86%;
  }
  .analysisIcon {
    top: 41.08%;
    bottom: 48.82%;
    cursor: pointer;
  }
  .settingsIcon {
    top: 53.37%;
    bottom: 36.53%;
  }
  .universalsidebar {
    position: fixed;
    top: 0;
    left: 0;
    
    border-radius: var(--br-21xl);
    width: 84px;
    height: 594px;
  }
  .topBarText {
    position: absolute;
    top: 33px;
    left: 20px;
    font-size: var(--font-size-13xl);
  }
  .topBarChild {
    position: absolute;
    top: 25px;
    left: 860px;
    border-radius: var(--br-5xs);
    background-color: var(--color-ghostwhite);
    border: 1px solid var(--color-aliceblue-100);
    box-sizing: border-box;
    width: 169px;
    height: 40px;
  }
  .vectorIcon {
    position: absolute;
    height: 17.99%;
    width: 1.44%;
    top: 33.65%;
    right: 30.91%;
    bottom: 48.36%;
    left: 67.64%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .search {
    position: absolute;
    top: 35px;
    left: 910px;
    color: var(--color-darkgray);
  }
  .topBarItem {
    position: absolute;
    top: 23px;
    left: 1089px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .loggedUser {
    position: absolute;
    top: 34px;
    left: 1136px;
    font-family: var(--font-source-sans-pro);
    color: var(--color-black);
  }
  .vectorIcon1 {
    position: absolute;
    height: 6.85%;
    width: 0.88%;
    top: 38.74%;
    right: 1.41%;
    bottom: 54.41%;
    left: 97.71%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  
  .topBar {
    position: fixed;
    background-color: var(--neutral-white);
  }
  .topBar {
    top: 0px;
    left: 126px;
    border-radius: var(--br-11xs);
    border: 1px solid var(--color-gray-500);
    box-sizing: border-box;
    width: 1295px;
    height: 104px;
    overflow: hidden;
    color: var(--color-gray-300);
  }
  .menubenchmarkingstudies {
    position: fixed;
    
    box-sizing: border-box;
    top: 100px;
    left: 114px;
    border-radius: var(--br-41xl);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 775px;
    height: 85px;
    overflow: hidden;
    background-color: var(--neutral-white);
  }
  .vectorIcon2 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .efficiencyStudy {
    position: absolute;
    top: 31.25%;
    left: 22.49%;
    line-height: 24px;
  }
  .buttongeneralvariant4 {
    position: fixed;
    top: 115px;
    left: 152px;
    width: 209px;
    height: 64px;
    overflow: hidden;
    cursor: pointer;
    color: var(--neutral-white);
    font-family: var(--font-source-sans-pro);
  }
  .efficiencyStudy1 {
    position: absolute;
    top: 31.25%;
    left: 22.49%;
    line-height: 24px;
    display: none;
  }
  .buttongeneralvariant41,
  .buttongeneralvariant42 {
    position: absolute;
    top: 860px;
    left: 465px;
    width: 209px;
    height: 64px;
    overflow: hidden;
    cursor: pointer;
    color: var(--neutral-white);
    font-family: var(--font-source-sans-pro);
  }
  .buttongeneralvariant42 {
    left: 813px;
  }
  .roleStudy {
    position: absolute;
    top: 31.25%;
    left: 29.67%;
    line-height: 24px;
  }
  .buttongeneralvariant6 {
    position: fixed;
    top: 115px;
    left: 643px;
    width: 209px;
    height: 64px;
    overflow: hidden;
    cursor: pointer;
  }
  .buttongeneralvariant5,
  .buttongeneralvariant6,
  .generate {
    font-family: var(--font-source-sans-pro);
    color: var(--neutral-white);
  }
  .buttongeneralvariant5 {
    position: fixed;
    top: 115px;
    left: 392px;
    width: 209px;
    height: 64px;
    overflow: hidden;
    cursor: pointer;
  }
  .generate {
    top: 85.94%;
    left: 35.5%;
  }
  .back,
  .efficiencyIndex,
  .generate {
    position: absolute;
    line-height: 24px;
    
  }
  .efficiencyIndex {
    top: 86%;
    left: 56.5%;
    font-family: var(--font-source-sans-pro);
    color: var(--neutral-white);
  }
  .back {
    top: 31.25%;
    left: 37.8%;
  }
  .buttongeneralvariant43 {
    position: absolute;
    top: 860px;
    left: 107px;
    width: 209px;
    height: 64px;
    overflow: hidden;
    cursor: pointer;
    color: var(--neutral-white);
    font-family: var(--font-source-sans-pro);
  }
  .benchmarkingEfficiency {
    position: relative;
    background-color: var(--neutral-white);
    width: 100%;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: var(--font-size-base);
    color: var(--color-black);
    font-family: var(--font-inter);
  }
  
  @media (max-width: 480px) {
    .dateInput {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  