.text {
  flex: 1;
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 130%;
  font-weight: 600;
  font-family: var(--font-inter);
  color: var(--color-black);
  text-align: left;
}
.cell,
.content {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
}
.cell {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-gray-400);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.text6 {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.cell6,
.row {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cell6 {
  background-color: var(--color-gray-400);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.row {
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-direction: row;
  font-size: var(--font-size-xl);
}
.text7 {
  flex: 1;
  position: relative;
  line-height: 130%;
}
.cell7 {
  align-self: stretch;
  background-color: var(--color-gray-600);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.cell7,
.row1,
.table,
.users {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.row1 {
  align-self: stretch;
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-direction: row;
}
.table,
.users {
  flex-direction: column;
}
.table {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-silver-200);
  overflow: hidden;
}
.users {
  position: absolute;
  top: 271px;
  left: 417px;
  width: 840px;
}
.userPlus2Icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button1 {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-source-sans-pro);
  color: var(--neutral-white);
  text-align: left;
}
.button {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-xl);
  background-color: var(--color-yellowgreen-200);
  position: absolute;
  top: 773px;
  left: 244px;
  border-radius: var(--br-7xl);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
}
.loadingContainer {
  position: fixed; /* Position the loader independently of other elements */
  top: 50%; /* Position halfway down the viewport */
  left: 50%; /* Position halfway across the viewport */
  transform: translate(-50%, -50%); /* Offset the loader's own dimensions */
  z-index: 9999; /* Ensure the loader is above all other elements */
}

.usersTable {
  position: relative; 
  /* Position the loader independently of other elements */
  top: 40%; /* Position halfway down the viewport */
  left: 47%; /* Position halfway across the viewport */
  transform: translate(-50%, -50%); /* Offset the loader's own dimensions */
  z-index: 9999; /* Ensure the loader is above all other elements */
  margin: 0 auto; /* Center the table horizontally */
  border-collapse: collapse; /* Remove spacing between cells */
  width: 90%; /* Set a specific width or adjust as needed */
  font-family: Arial, sans-serif; /* Set a professional font family */
}

.usersTable table {
  border: 2px solid #a2c73b; /* Set the border color */
  width: 100%;
}

.usersTable th,
.usersTable td {
  text-align: left; /* Align text to the left */
  padding: 12px 15px; /* Add padding to cells */
  border-bottom: 1px solid #a2c73b; /* Add border to the bottom of the cells */
}

.usersTable th {
  background-color: #f4f4f4; /* Set background color for the header row */
  color: #333; /* Set text color for the header row */
}

.usersTable tr:nth-child(even) {
  background-color: #f9f9f9; /* Add a background color to even rows */
}

.usersTable tr:hover {
  background-color: #f2f2f2; /* Add a hover effect to rows */
}


.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.topBarText {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}
.topBarChild {
  position: absolute;
  top: 25px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
}
.vectorIcon {
  position: absolute;
  height: 17.99%;
  width: 1.44%;
  top: 33.65%;
  right: 30.91%;
  bottom: 48.36%;
  left: 67.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 35px;
  left: 910px;
  color: var(--color-darkgray);
}
.topBarItem {
  position: absolute;
  top: 23px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.loggedUser {
  position: absolute;
  top: 34px;
  left: 1136px;
  font-family: var(--font-source-sans-pro);
  color: var(--color-black);
}
.vectorIcon1 {
  height: 6.85%;
  width: 0.88%;
  top: 38.74%;
  right: 1.41%;
  bottom: 54.41%;
  left: 97.71%;
  max-width: 100%;
  max-height: 100%;
}
.topBar,
.vectorIcon1,
.vectorIcon2 {
  position: absolute;
  overflow: hidden;
}
.topBar {
  top: 4px;
  left: 89px;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
}
.vectorIcon2 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.addUser,
.adduserIcon {
  position: absolute;
  top: 31.25%;
}
.addUser {
  left: 29.67%;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-source-sans-pro);
  color: var(--neutral-white);
  text-align: left;
}
.adduserIcon {
  height: 37.5%;
  width: 11.48%;
  right: 71.77%;
  bottom: 31.25%;
  left: 16.75%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.buttongeneralvariant4 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 140px;
  left: 1155px;
  width: 209px;
  height: 64px;
  overflow: hidden;
}
.viewUsersSuper {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-inter);
}
