@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aclonica:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-inter: Inter;
  --font-source-sans-pro: "Source Sans Pro";
  --font-aclonica: Aclonica;

  /* font sizes */
  --font-size-13xl: 32px;
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --font-size-5xl: 24px;
  --font-size-xs: 12px;
  --font-size-xl: 20px;

  /* Colors */
  --neutral-white: #fff;
  --color-gray-100: #808285;
  --color-gray-200: #7f7f7f;
  --color-gray-300: #1c1f37;
  --color-gray-400: rgba(0, 0, 0, 0.06);
  --color-gray-600: rgba(255, 255, 255, 0);
  --color-gray-500: rgba(0, 0, 0, 0.1);
  --color-black: #000;
  --color-darkgray: #979797;
  --color-ghostwhite: #f5f7fb;
  --color-aliceblue-100: #e3e8f1;
  --color-deepskyblue-100: #00c0f3;
  --color-whitesmoke-100: #f6f6f6;
  --color-whitesmoke-200: #e7eaee;
  --neutral-200: #e5e7eb;
  --color-yellowgreen-100: #98cb4f;
  --color-yellowgreen-200: #a2c73b;
  --color-silver-100: #c7c8ca;
  --color-silver-200: #b9b9b9;
  --color-orange: #f7a12c;
  --neutral-300: #d1d5db;
  --color-darkslategray: #3c4555;
  --color-crimson: #da1e4e;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-xs: 12px;
  --gap-12xs: 1px;
  --gap-25xl: 44px;
  --gap-2xl: 21px;
  --gap-6xs: 7px;
  --gap-3xs: 10px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-5xl: 24px;

  /* Paddings */
  --padding-5xs: 8px;
  --padding-9xs: 4px;
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-xs: 12px;
  --padding-sm: 14px;
  --padding-25xl: 44px;
  --padding-5xl: 24px;
  --padding-17xl: 36px;
  --padding-81xl: 100px;
  --padding-2xs: 11px;

  /* border radiuses */
  --br-11xs: 2px;
  --br-5xs: 8px;
  --br-21xl: 40px;
  --br-6xl: 25px;
  --br-4xs: 9px;
  --br-3xs: 10px;
  --br-9xs: 4px;
  --br-41xl: 60px;
  --br-7xl: 26px;
  --br-8xs: 5px;
  --br-81xl: 100px;

  /* Effects */
  --long: 0px 100px 104px rgba(11, 79, 119, 0.07),
    0px 38.01102066040039px 37.96px rgba(11, 79, 119, 0.05),
    0px 19.98758316040039px 18.43px rgba(11, 79, 119, 0.05),
    0px 10.786386489868164px 9.03px rgba(11, 79, 119, 0.05),
    0px 4.721857070922852px 3.57px rgba(11, 79, 119, 0.04);
  --blue: 0px 10px 20px rgba(0, 192, 243, 0.3);
}
