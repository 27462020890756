.superUserMenuChild,
.superUserMenuItem {
  position: absolute;
  top: 393px;
  left: 175px;
  border-radius: var(--br-4xs) var(--br-4xs) 0 0;
  background-color: var(--color-whitesmoke-100);
  width: 512px;
  height: 138px;
}
.superUserMenuItem {
  top: 531px;
  border-radius: 0 0 var(--br-4xs) var(--br-4xs);
  background-color: var(--neutral-white);
}
.day,
.days {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 17px;
  flex-shrink: 0;
}
.days {
  width: 47px;
}
.notification {
  position: absolute;
  top: 454px;
  left: 620px;
  width: 47px;
  height: 154px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 120px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
}
.efficiencyStudy1Has,
.fileUploaded {
  position: absolute;
  left: 61px;
  display: inline-block;
}
.fileUploaded {
  top: 0;
  font-weight: 500;
  width: 118px;
  height: 21px;
}
.efficiencyStudy1Has {
  top: 32px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
  width: 274px;
  height: 22px;
}
.notification1,
.notification1Child {
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 46px;
}
.notification1 {
  top: 439px;
  left: 214px;
  width: 335px;
  height: 54px;
  color: var(--color-black);
}
.passwordReset,
.user1HasReset {
  position: absolute;
  left: 61px;
  display: inline-block;
}
.passwordReset {
  top: 0;
  font-weight: 500;
  width: 146px;
  height: 21px;
}
.user1HasReset {
  top: 32px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
  width: 356px;
  height: 22px;
}
.notification2 {
  position: absolute;
  top: 576px;
  left: 214px;
  width: 417px;
  height: 54px;
  color: var(--color-black);
}
.superUserMenuInner {
  top: 370px;
  border-radius: var(--br-4xs) var(--br-4xs) 0 0;
  background-color: var(--neutral-white);
  height: 138px;
}
.rectangleDiv,
.superUserMenuChild1,
.superUserMenuInner {
  position: absolute;
  left: 732px;
  width: 512px;
}
.rectangleDiv {
  top: 505px;
  background-color: var(--neutral-white);
  height: 151px;
}
.superUserMenuChild1 {
  top: 643px;
  border-radius: 0 0 var(--br-4xs) var(--br-4xs);
  background-color: #edf3ff;
  height: 61px;
}
.day1,
.days1,
.viewAll {
  position: absolute;
  display: inline-block;
}
.viewAll {
  top: 663px;
  left: 952px;
  font-weight: 600;
  color: #24befe;
  width: 73px;
  height: 21px;
}
.day1,
.days1 {
  top: 430px;
  left: 1181px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
  width: 36px;
  height: 17px;
}
.days1 {
  top: 565px;
  width: 47px;
}
.exportedVisualisations,
.marios {
  position: absolute;
  left: 61px;
  display: inline-block;
}
.marios {
  top: 0;
  font-weight: 500;
  width: 163px;
  height: 21px;
}
.exportedVisualisations {
  top: 29px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
  width: 311px;
  height: 37px;
}
.frameChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  object-fit: cover;
}
.mariosParent {
  position: absolute;
  top: 416px;
  left: 771px;
  width: 372px;
  height: 66px;
  color: var(--color-black);
}
.ellie,
.requestedAccessTo {
  position: absolute;
  left: 832px;
  display: inline-block;
}
.ellie {
  top: 551px;
  font-weight: 500;
  color: var(--color-black);
  width: 108px;
  height: 21px;
}
.requestedAccessTo {
  top: 583px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
  width: 311px;
  height: 17px;
}
.ellipseIcon {
  position: absolute;
  top: 551px;
  left: 771px;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  object-fit: cover;
}
.superUserMenuChild2,
.superUserMenuChild3 {
  position: absolute;
  top: 392px;
  left: 732px;
  border-radius: 0 var(--br-6xl) var(--br-6xl) 0;
  background-color: #ed1316;
  width: 8px;
  height: 91px;
}
.superUserMenuChild3 {
  top: 527px;
  background-color: #00a1df;
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
  display: none;
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon {
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  display: none;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 0;
  left: 11px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.topBarText {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}
.topBarChild {
  position: absolute;
  top: 25px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
}
.vectorIcon {
  position: absolute;
  height: 17.99%;
  width: 1.44%;
  top: 33.65%;
  right: 30.91%;
  bottom: 48.36%;
  left: 67.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 35px;
  left: 910px;
  color: var(--color-darkgray);
}
.topBarItem {
  position: absolute;
  top: 23px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.loggedUser {
  position: absolute;
  top: 34px;
  left: 1136px;
  font-family: var(--font-source-sans-pro);
  color: var(--color-black);
}
.vectorIcon1 {
  height: 6.85%;
  width: 0.88%;
  top: 38.74%;
  right: 1.41%;
  bottom: 54.41%;
  left: 97.71%;
  max-width: 100%;
  max-height: 100%;
}
.topBar,
.vectorIcon1,
.vectorIcon2 {
  position: absolute;
  overflow: hidden;
}
.topBar {
  top: 9px;
  left: 138px;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
  color: var(--color-gray-300);
}
.vectorIcon2 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.userIcon,
.viewUsers {
  position: absolute;
  top: 31.25%;
}
.viewUsers {
  left: 29.67%;
  line-height: 24px;
}
.userIcon {
  height: 37.5%;
  width: 11.48%;
  right: 68.42%;
  bottom: 31.25%;
  left: 20.1%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.buttongeneralvariant4 {
  position: absolute;
  top: 184px;
  left: 170px;
  width: 209px;
  height: 64px;
  overflow: hidden;
  cursor: pointer;
  font-family: var(--font-source-sans-pro);
}
.adduserIcon {
  position: absolute;
  height: 37.5%;
  width: 11.48%;
  top: 31.25%;
  right: 71.77%;
  bottom: 31.25%;
  left: 16.75%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.buttongeneralvariant41,
.buttongeneralvariant42,
.buttongeneralvariant43,
.buttongeneralvariant44 {
  position: absolute;
  width: 209px;
  height: 64px;
  overflow: hidden;
  font-family: var(--font-source-sans-pro);
}
.buttongeneralvariant41 {
  top: 180px;
  left: 445px;
  cursor: pointer;
}
.buttongeneralvariant42,
.buttongeneralvariant43,
.buttongeneralvariant44 {
  top: 177px;
  left: 742px;
}
.buttongeneralvariant43,
.buttongeneralvariant44 {
  top: 174px;
  left: 1023px;
}
.buttongeneralvariant44 {
  top: 877px;
  left: 1190px;
  cursor: pointer;
}
.notifications {
  position: absolute;
  top: 328px;
  left: 197px;
  font-size: var(--font-size-13xl);
  color: var(--color-gray-300);
}
.superUserMenu {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 979px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-inter);
}
