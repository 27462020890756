.clipPathGroup {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.byMonth {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.dropdownMenuOption,
.dropdownMenuOption1 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: center;
}
.dropdownMenuOption {
  border-radius: var(--br-9xs);
  background-color: var(--color-deepskyblue-100);
  overflow: hidden;
  display: none;
  justify-content: flex-start;
  color: var(--neutral-white);
}
.dropdownMenuOption1 {
  border-radius: var(--br-11xs);
  cursor: pointer;
}
.dropdownMenuContents,
.dropdownMenuOption1,
.dropdownMenuOption2 {
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}
.dropdownMenuOption2 {
  align-self: stretch;
  border-radius: var(--br-11xs);
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: center;
}
.dropdownMenuContents {
  position: absolute;
  height: 103.23%;
  width: 104.12%;
  top: 0;
  right: -4.12%;
  bottom: -3.23%;
  left: 0;
  border-radius: var(--br-3xs);
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--neutral-200);
  box-sizing: border-box;
  flex-direction: column;
  padding: var(--padding-5xs);
  align-items: flex-start;
  gap: var(--gap-9xs);
  
}
.overlaymenuanalysismenu {
  position: relative;
  width: 194px;
  height: 186px;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-source-sans-pro);
  
}

.dropdownMenuOption:hover,
.dropdownMenuOption1:hover {
  background-color: '#a2c73b'; /* Replace with the color you want */
}

