.addUser1 {
  position: absolute;
  top: 94px;
  left: 665px;
  line-height: 28px;
  color: var(--color-black);
  display: inline-block;
  width: 265px;
}
.groupIcon {
  position: absolute;
  height: 5%;
  width: 4.72%;
  top: 4.98%;
  right: 93.96%;
  bottom: 90.01%;
  left: 1.32%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.sidebarBg {
  position: absolute;
  height: 99.91%;
  width: 98.74%;
  top: 0.04%;
  right: 0.63%;
  bottom: 0.04%;
  left: 0.63%;
  background-color: var(--neutral-white);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.03);
  border: 1px solid #ececec;
  box-sizing: border-box;
  display: none;
}
.version {
  margin: 0;
}
.version185 {
  position: absolute;
  top: 93.73%;
  left: 23.31%;
  display: none;
}
.clipboard2TonesIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.customers,
.reports {
  background-color: transparent;
  display: flex;
  align-items: center;
}
.customers,
.projects,
.reports {
  border-radius: var(--br-21xl);
  width: 60px;
  height: 60px;
  flex-direction: row;
  padding: var(--padding-2xs);
  box-sizing: border-box;
  justify-content: center;
}
.menu,
.projects,
.users {
  display: flex;
  align-items: center;
}
.users {
  border-radius: var(--br-21xl);
  background-color: rgba(162, 199, 59, 0.2);
  width: 60px;
  height: 60px;
  flex-direction: row;
  padding: var(--padding-2xs);
  box-sizing: border-box;
  justify-content: center;
}
.menu {
  position: absolute;
  top: 119px;
  left: 13px;
  background-color: var(--neutral-white);
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.retimeLogoIcon {
  position: absolute;
  top: 15px;
  left: calc(50% - 31px);
  width: 60px;
  height: 43.37px;
  overflow: hidden;
  display: none;
}
.collapseIcon,
.topLineSeparator {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
}
.collapseIcon {
  height: 3.65%;
  width: 36.78%;
  top: 9.69%;
  right: -17.24%;
  bottom: 86.66%;
  left: 80.46%;
  max-height: 100%;
  display: none;
}
.topLineSeparator {
  width: 97.7%;
  top: 76.5px;
  right: 1.15%;
  left: 1.15%;
  height: 1px;
}
.sidebar {
  position: absolute;
  top: 42px;
  left: 19px;
  width: 96px;
  height: 722px;
  font-size: var(--font-size-xs);
  font-family: var(--font-aclonica);
}
.button {
  border-radius: var(--br-21xl);
  background-color: var(--color-yellowgreen-200);
  box-shadow: var(--blue);
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs);
  align-items: center;
  justify-content: center;
}
.photo {
  position: relative;
  font-weight: 600;
}
.step {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-black);
}
.separatorIcon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 26px;
}
.button1,
.step1 {
  display: flex;
  align-items: center;
}
.button1 {
  border-radius: var(--br-21xl);
  background-color: var(--color-silver-100);
  flex-direction: row;
  padding: var(--padding-2xs);
  justify-content: center;
}
.step1 {
  width: 60px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.separatorIcon1 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 27px;
}
.stepper {
  align-self: stretch;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-81xl);
  align-items: flex-start;
  justify-content: center;
}
.link {
  position: relative;
  text-decoration: underline;
  line-height: 24px;
  font-weight: 600;
}
.button3 {
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dropAPhoto {
  flex: 1;
  position: relative;
  font-weight: 600;
}
.button4,
.dropAPhotoHereOrParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button4 {
  flex: 1;
  border-radius: var(--br-3xs);
  justify-content: center;
  text-align: left;
  color: var(--color-yellowgreen-200);
}
.dropAPhotoHereOrParent {
  align-self: stretch;
  justify-content: flex-start;
  text-align: center;
  color: var(--color-gray-100);
}
.buttonParent,
.dropYourPhoto {
  display: flex;
  flex-direction: column;
}
.buttonParent {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.dropYourPhoto {
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  border: 1px dashed var(--color-silver-100);
  box-sizing: border-box;
  width: 597px;
  padding: 38px 163px;
  align-items: flex-start;
  justify-content: flex-end;
}
.stepperFooterChild {
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  height: 1px;
}
.plusCircleIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button6 {
  position: relative;
  line-height: 24px;
}
.button5 {
  border-radius: var(--br-7xl);
  border: 2px solid var(--color-yellowgreen-200);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.fileTextIcon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 52px;
}
.progress1,
.progressBg {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--color-silver-100);
  width: 155.81px;
  height: 5px;
}
.progress1 {
  background-color: var(--color-deepskyblue-100);
  width: 133.09px;
}
.progress {
  align-self: stretch;
  position: relative;
  height: 5px;
}
.of57Mb,
.uploading72 {
  position: relative;
  line-height: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 77.03px;
  flex-shrink: 0;
}
.uploading72 {
  font-size: var(--font-size-base);
  color: var(--color-deepskyblue-100);
  text-align: right;
  width: 121.05px;
}
.fileInfo,
.sizeAndProgress {
  display: flex;
  align-items: flex-start;
}
.sizeAndProgress {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-sm);
  color: var(--color-gray-100);
}
.fileInfo {
  flex: 1;
  height: 53px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.button7,
.uploadingStatus {
  flex-direction: row;
  align-items: center;
}
.uploadingStatus {
  width: 311.62px;
  display: none;
  justify-content: flex-start;
  color: var(--color-black);
}
.button7 {
  border-radius: var(--br-7xl);
  background-color: var(--color-yellowgreen-200);
  display: flex;
  padding: var(--padding-5xs) var(--padding-xl);
  justify-content: center;
  cursor: pointer;
  color: var(--neutral-white);
}
.buttonGroup,
.stepperFooter {
  align-self: stretch;
  align-items: center;
}
.buttonGroup {
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-17xl);
  justify-content: space-between;
}
.stepperFooter {
  padding: 0 0 var(--padding-5xl);
  gap: var(--gap-2xl);
  color: var(--color-yellowgreen-200);
}
.content,
.step11,
.stepperFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.step11 {
  align-self: stretch;
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--neutral-white);
  padding: var(--padding-25xl) 0 0;
  align-items: center;
  gap: var(--gap-25xl);
  text-align: left;
  color: var(--color-crimson);
}
.content {
  position: absolute;
  top: 122px;
  left: 319px;
  box-shadow: var(--long);
  width: 872px;
  align-items: flex-start;
  gap: var(--gap-12xs);
  font-size: var(--font-size-base);
  font-family: var(--font-source-sans-pro);
}
.addUser {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-silver-100);
  font-family: var(--font-inter);
}
