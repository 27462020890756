.vectorIcon {
  position: absolute;
  height: 29.67%;
  width: 47.56%;
  top: 26.22%;
  right: 13.72%;
  bottom: 44.11%;
  left: 38.72%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.bxbxChevronDown {
  position: relative;
  width: 100%;
  height: 24px;
  overflow: hidden;
}
