.coreAnalysis {
  position: absolute;
  top: 47px;
  left: 136px;
  font-size: var(--font-size-13xl);
  font-family: var(--font-inter);
  color: var(--color-gray-300);
}
.vectorIcon {
  position: absolute;
  height: 1.83%;
  width: 1.3%;
  top: 5.18%;
  right: 29.81%;
  bottom: 93%;
  left: 68.89%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 53.5px;
  left: 1026px;
  font-family: var(--font-inter);
  color: var(--color-darkgray);
}
.coreAnalysisMenuChild {
  position: absolute;
  top: 46px;
  left: 1205px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.rushanArshad {
  position: absolute;
  top: 58px;
  left: 1252px;
  font-family: var(--font-inter);
  color: var(--color-black);
}
.vectorIcon1 {
  position: absolute;
  height: 0.7%;
  width: 0.79%;
  top: 6.28%;
  right: 3.28%;
  bottom: 93.03%;
  left: 95.92%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.userPlus2Icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button1 {
  position: relative;
  line-height: 24px;
}
.button {
  position: absolute;
  top: 909px;
  left: 200px;
  border-radius: var(--br-7xl);
  background-color: var(--color-yellowgreen-200);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  cursor: pointer;
}
.menubenchmarkingstudies {
  position: absolute;
  top: 143px;
  left: 137px;
  border-radius: var(--br-41xl);
  background-color: var(--neutral-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 1247px;
  height: 75px;
}
.vectorIcon2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.efficiencyStudy {
  position: absolute;
  top: 31.25%;
  left: 22.49%;
  line-height: 24px;
}
.buttongeneralvariant4 {
  position: absolute;
  top: 149px;
  left: 172px;
  width: 209px;
  height: 64px;
  overflow: hidden;
  cursor: pointer;
}
.activityStudy {
  position: absolute;
  top: 31.25%;
  left: 29.67%;
  line-height: 24px;
}
.buttongeneralvariant5,
.buttongeneralvariant6 {
  position: absolute;
  top: 150px;
  left: 392px;
  width: 209px;
  height: 64px;
  overflow: hidden;
  cursor: pointer;
}
.buttongeneralvariant6 {
  top: 148px;
  left: 626px;
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 5px;
  left: 14px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.coreAnalysisMenu {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
