.showBenchmarkingChartsChild {
  position: absolute;
  top: 267px;
  left: 1052px;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 15px;
  left: 19px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.topBarText {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}
.topBarChild {
  position: absolute;
  top: 25px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
}
.vectorIcon {
  position: absolute;
  height: 17.99%;
  width: 1.44%;
  top: 33.65%;
  right: 30.91%;
  bottom: 48.36%;
  left: 67.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 35px;
  left: 910px;
  color: var(--color-darkgray);
}
.topBarItem {
  position: absolute;
  top: 23px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.loggedUser {
  position: absolute;
  top: 34px;
  left: 1136px;
  font-family: var(--font-source-sans-pro);
  color: var(--color-black);
}
.vectorIcon1 {
  height: 6.85%;
  width: 0.88%;
  top: 38.74%;
  right: 1.41%;
  bottom: 54.41%;
  left: 97.71%;
  max-width: 100%;
  max-height: 100%;
}
.topBar,
.vectorIcon1,
.vectorIcon2 {
  position: absolute;
  overflow: hidden;
}
.topBar {
  top: 9px;
  left: 108px;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
  color: var(--color-gray-300);
  font-family: var(--font-inter);
}
.vectorIcon2 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.logout {
  position: absolute;
  top: 31.25%;
  left: 29.67%;
  line-height: 24px;
  display: none;
}
.buttongeneralvariant4,
.buttongeneralvariant41 {
  position: absolute;
  top: 872px;
  left: 144px;
  width: 209px;
  height: 64px;
  overflow: hidden;
  cursor: pointer;
}
.buttongeneralvariant41 {
  top: 518px;
  left: 371px;
}
.back,
.export {
  position: absolute;
  top: 91.86%;
  left: 15.66%;
  line-height: 24px;
}
.export {
  top: 55.41%;
  left: 31.88%;
}
.showBenchmarkingChartsItem {
  position: absolute;
  top: 142px;
  left: 199px;
  background-color: #d9d9d9;
  width: 593px;
  height: 319px;
}
.showBenchmarkingCharts {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 971px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
