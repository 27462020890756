.coreAnalysisEfficiency1 {
  position: absolute;
  top: 47px;
  left: 136px;
  font-size: var(--font-size-13xl);
  font-family: var(--font-inter);
  color: var(--color-gray-300);
}
.vectorIcon {
  position: absolute;
  height: 1.83%;
  width: 1.3%;
  top: 5.18%;
  right: 29.81%;
  bottom: 93%;
  left: 68.89%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 53.5px;
  left: 1026px;
  font-family: var(--font-inter);
  color: var(--color-darkgray);
}
.coreAnalysisEfficiencyChild {
  position: absolute;
  top: 46px;
  left: 1205px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.rushanArshad {
  position: absolute;
  top: 58px;
  left: 1252px;
  font-family: var(--font-inter);
}
.vectorIcon1 {
  position: absolute;
  height: 0.7%;
  width: 0.79%;
  top: 6.28%;
  right: 3.28%;
  bottom: 93.03%;
  left: 95.92%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.userPlus2Icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button1 {
  position: relative;
  line-height: 24px;
}
.analysisContentAreaChild,
.button {
  position: absolute;
  box-sizing: border-box;
}
.button {
  top: 812px;
  left: 147px;
  border-radius: var(--br-7xl);
  background-color: var(--color-yellowgreen-200);
  width: 209px;
  height: 64px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  cursor: pointer;
  color: var(--neutral-white);
}
.analysisContentAreaChild {
  top: -0.5px;
  left: -0.5px;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke-100);
  border: 1px dashed var(--color-silver-100);
  width: 1017px;
  height: 569px;
}
.dateRange {
  position: absolute;
  top: calc(50% - 154.03px);
  left: calc(50% - 377.54px);
  line-height: 28px;
  display: inline-block;
  width: 145.45px;
  height: 36.76px;
}
.analysisContentAreaInner,
.analysisContentAreaItem {
  position: absolute;
  top: 138.98px;
  left: 562.55px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 173.05px;
  height: 55.73px;
}
.analysisContentAreaInner {
  left: 802.48px;
  width: 183.67px;
}
.industry,
.to {
  position: absolute;
  line-height: 28px;
  display: inline-block;
  height: 36.76px;
}
.to {
  top: 147.28px;
  left: 748.34px;
  width: 30.79px;
}
.industry {
  top: calc(50% - 233.48px);
  left: calc(50% - 377.54px);
  width: 102.98px;
}
.rectangleDiv {
  position: absolute;
  top: 50.04px;
  left: 562.55px;
  background-color: var(--neutral-white);
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  width: 423.6px;
  height: 56.92px;
}
.polygonIcon {
  position: absolute;
  bottom: 513.85px;
  left: 951.28px;
  width: 19.08px;
  height: 16.38px;
}
.text {
  flex: 1;
  position: relative;
  line-height: 130%;
  font-weight: 600;
}
.content {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xs);
}
.cell,
.content,
.row {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cell {
  background-color: var(--color-gray-400);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.row {
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-direction: row;
  gap: var(--gap-12xs);
  font-size: var(--font-size-xl);
}
.text4 {
  flex: 1;
  position: relative;
  line-height: 130%;
}
.cell4 {
  align-self: stretch;
  background-color: var(--color-gray-600);
  border-top: 1px solid var(--color-silver-200);
  border-left: 1px solid var(--color-silver-200);
  box-sizing: border-box;
  width: 120px;
  flex-direction: column;
}
.button2,
.cell4,
.row1,
.table,
.table1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.row1 {
  align-self: stretch;
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-direction: row;
  gap: var(--gap-12xs);
}
.button2,
.table,
.table1 {
  flex-direction: column;
}
.table {
  align-self: stretch;
  border-radius: var(--br-9xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-silver-200);
  overflow: hidden;
}
.button2,
.table1 {
  position: absolute;
  top: 327.52px;
  left: 18.99px;
  width: 528.7px;
  text-align: left;
  font-size: var(--font-size-xs);
}
.button2 {
  top: 472px;
  left: 591px;
  border-radius: var(--br-7xl);
  background-color: var(--color-orange);
  width: 209px;
  height: 64px;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-7xs);
  cursor: pointer;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
  font-family: var(--font-source-sans-pro);
}
.analysisContentArea {
  position: absolute;
  top: 224px;
  left: 174px;
  width: 1016px;
  height: 605px;
  text-align: center;
  font-size: var(--font-size-5xl);
  font-family: var(--font-inter);
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 6px;
  left: 9px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.menubenchmarkingstudies {
  position: absolute;
  top: 131px;
  left: 187px;
  border-radius: var(--br-41xl);
  background-color: var(--neutral-white);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 738px;
  height: 60px;
}
.vectorIcon2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.efficiencyStudy {
  position: absolute;
  top: 19.35%;
  left: 22.49%;
  line-height: 24px;
  display: none;
}
.buttongeneralvariant4,
.buttongeneralvariant41,
.buttongeneralvariant42 {
  position: absolute;
  top: 140px;
  left: 207px;
  width: 87px;
  height: 42px;
  overflow: hidden;
  cursor: pointer;
  color: var(--neutral-white);
}
.buttongeneralvariant41,
.buttongeneralvariant42 {
  left: 294px;
}
.buttongeneralvariant42 {
  left: 381px;
}
.buttongeneralvariant43,
.buttongeneralvariant44,
.buttongeneralvariant45,
.buttongeneralvariant46,
.buttongeneralvariant47 {
  position: absolute;
  top: 140px;
  left: 468px;
  width: 87px;
  height: 42px;
  overflow: hidden;
  cursor: pointer;
  color: var(--neutral-white);
}
.buttongeneralvariant44,
.buttongeneralvariant45,
.buttongeneralvariant46,
.buttongeneralvariant47 {
  left: 555px;
}
.buttongeneralvariant45,
.buttongeneralvariant46,
.buttongeneralvariant47 {
  left: 642px;
}
.buttongeneralvariant46,
.buttongeneralvariant47 {
  left: 729px;
}
.buttongeneralvariant47 {
  left: 817px;
}
.area,
.industry1,
.sector {
  position: absolute;
  top: 148px;
  left: 221px;
  line-height: 24px;
}
.area,
.sector {
  left: 308px;
}
.area {
  left: 402px;
}
.category,
.customer,
.element,
.task,
.timeOfDay {
  position: absolute;
  top: 148px;
  left: 480px;
  line-height: 24px;
}
.customer,
.element,
.task,
.timeOfDay {
  left: 567px;
}
.customer,
.task,
.timeOfDay {
  left: 667px;
}
.customer,
.timeOfDay {
  left: 732px;
}
.customer {
  top: 147px;
  left: 827px;
}
.coreAnalysisEfficiency {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-source-sans-pro);
}
