.checkIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  border-radius: var(--br-21xl);
  background-color: var(--color-yellowgreen-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs);
  align-items: center;
  justify-content: center;
}
.photo {
  position: relative;
  font-weight: 600;
}
.step {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.separatorIcon,
.separatorIcon1 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 26px;
}
.separatorIcon1 {
  height: 27px;
}
.button2,
.step2 {
  display: flex;
  align-items: center;
}
.button2 {
  border-radius: var(--br-21xl);
  background-color: var(--color-deepskyblue-100);
  box-shadow: var(--blue);
  flex-direction: row;
  padding: var(--padding-2xs);
  justify-content: center;
}
.step2 {
  width: 60px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-black);
}
.stepper {
  align-self: stretch;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-81xl);
  align-items: flex-start;
  justify-content: center;
}
.studyApp,
.studyNames {
  position: absolute;
  top: 0;
  left: 1px;
  line-height: 24px;
}
.studyNames {
  top: 190px;
  font-size: var(--font-size-sm);
}
.checkboxRadioButton {
  position: relative;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 2px solid var(--neutral-300);
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}
.option {
  position: relative;
}
.inputCheckbox,
.inputCheckboxParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.inputCheckboxParent {
  position: absolute;
  top: 40px;
  left: 0;
  flex-direction: column;
  gap: var(--gap-base);
  color: var(--color-black);
}
.checkboxRadioButton3 {
  position: relative;
  border-radius: var(--br-11xs);
  width: 20px;
  height: 20px;
}
.inputCheckboxGroup {
  position: absolute;
  top: 220px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  color: var(--color-black);
}
.studyAppPermissionsParent {
  flex: 1;
  position: relative;
  height: 276px;
}
.userPermissions {
  position: relative;
  line-height: 24px;
}
.checkboxRadioButton5,
.checkboxRadioButton6 {
  position: relative;
  border-radius: var(--br-81xl);
  width: 20px;
  height: 20px;
}
.checkboxRadioButton6 {
  background-color: var(--neutral-white);
  border: 2px solid var(--neutral-300);
  box-sizing: border-box;
}
.inputCheckboxContainer {
  flex-direction: column;
  gap: var(--gap-base);
  color: var(--color-black);
}
.frameParent,
.inputCheckboxContainer,
.userPermissionsParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.userPermissionsParent {
  flex: 1;
  height: 132px;
  flex-direction: column;
  padding: 0 0 0 1px;
  box-sizing: border-box;
  gap: var(--gap-base);
}
.frameParent {
  align-self: stretch;
  flex-direction: row;
  gap: 127px;
}
.stepperFooterChild {
  position: relative;
  border-top: 1px solid var(--color-whitesmoke-200);
  box-sizing: border-box;
  width: 873px;
  height: 1px;
}
.plusCircleIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button3 {
  border-radius: var(--br-7xl);
  border: 2px solid var(--color-yellowgreen-200);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
  cursor: pointer;
}
.fileTextIcon {
  position: relative;
  width: 46.62px;
  height: 52px;
  overflow: hidden;
  flex-shrink: 0;
}
.progress1,
.progressBg {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--color-silver-100);
  width: 265px;
  height: 5px;
}
.progress1 {
  background-color: var(--color-deepskyblue-100);
  width: 226.35px;
}
.progress {
  align-self: stretch;
  position: relative;
  height: 5px;
}
.of57Mb,
.uploading72 {
  position: relative;
  line-height: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 77.03px;
  flex-shrink: 0;
}
.uploading72 {
  font-size: var(--font-size-base);
  color: var(--color-deepskyblue-100);
  text-align: right;
  width: 121.05px;
}
.fileInfo,
.sizeAndProgress {
  display: flex;
  align-items: flex-start;
}
.sizeAndProgress {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-sm);
  color: var(--color-gray-100);
}
.fileInfo {
  width: 265px;
  height: 53px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-6xs);
}
.uploadingStatus {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-black);
}
.button5,
.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button5 {
  border-radius: var(--br-7xl);
  background-color: var(--color-yellowgreen-200);
  padding: var(--padding-5xs) var(--padding-xl);
  justify-content: center;
  cursor: pointer;
  color: var(--neutral-white);
}
.buttonParent {
  align-self: stretch;
  padding: 0 var(--padding-17xl);
  justify-content: space-between;
}
.stepperFooter,
.stepperFooter1 {
  align-self: stretch;
  justify-content: flex-start;
}
.stepperFooter1 {
  display: flex;
  flex-direction: column;
  padding: 0 0 var(--padding-5xl);
  align-items: center;
  gap: var(--gap-2xl);
}
.stepperFooter {
  align-items: flex-start;
  color: var(--color-yellowgreen-200);
}
.content,
.step21,
.stepperFooter {
  display: flex;
  flex-direction: column;
}
.step21 {
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--neutral-white);
  width: 978px;
  padding: var(--padding-25xl) 0 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-25xl);
  text-align: left;
  color: var(--color-darkslategray);
}
.content {
  position: absolute;
  top: 201px;
  left: 380px;
  box-shadow: var(--long);
  width: 872px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
}
.topBarText {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}
.topBarChild {
  position: absolute;
  top: 25px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
}
.vectorIcon {
  position: absolute;
  height: 17.99%;
  width: 1.44%;
  top: 33.65%;
  right: 30.91%;
  bottom: 48.36%;
  left: 67.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 35px;
  left: 910px;
  color: var(--color-darkgray);
}
.topBarItem {
  position: absolute;
  top: 23px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.loggedUser {
  position: absolute;
  top: 34px;
  left: 1136px;
  font-family: var(--font-source-sans-pro);
  color: var(--color-black);
}
.vectorIcon1 {
  position: absolute;
  height: 6.85%;
  width: 0.88%;
  top: 38.74%;
  right: 1.41%;
  bottom: 54.41%;
  left: 97.71%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.topBar,
.universalsidebar1 {
  position: absolute;
  background-color: var(--neutral-white);
}
.topBar {
  top: 14px;
  left: 138px;
  border-radius: var(--br-11xs);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
  overflow: hidden;
  text-align: left;
  color: var(--color-gray-300);
  font-family: var(--font-inter);
}
.universalsidebar1 {
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 9px;
  left: 12px;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.addUser2 {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-yellowgreen-100);
  font-family: var(--font-source-sans-pro);
}
