.login {
  
  background-color: var(--color-whitesmoke-100);
  width: 100%;
  right:60.93;
  height: 875px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  margin: 0; /* Remove default margin */
}
