.surface1Icon {
  position: absolute;
  height: 99.73%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0.27%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.rethinkProductivityLogo1 {
  position: relative;
  width: 100%;
  height: 193.33px;
  overflow: hidden;
}
