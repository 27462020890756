.byMonth {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.dropdownMenuOption,
.dropdownMenuOption1 {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
}
.dropdownMenuOption {
  border-radius: var(--br-9xs);
  background-color: var(--color-deepskyblue-100);
  overflow: hidden;
  display: none;
  color: var(--neutral-white);
}
.dropdownMenuOption1 {
  border-radius: var(--br-11xs);
  display: flex;
  cursor: pointer;
}
.dropdownMenuOption1,
.dropdownMenuOption2,
.dropdownMenuOption3 {
  background-color: var(--neutral-white);
  overflow: hidden;
}
.dropdownMenuOption2 {
  align-self: stretch;
  border-radius: var(--br-11xs);
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
}
.dropdownMenuOption3 {
  cursor: pointer;
}
.dropdownMenuOption3,
.dropdownMenuOption4 {
  align-self: stretch;
  border-radius: var(--br-11xs);
  display: none;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
}
.dropdownMenuContents,
.dropdownMenuOption4,
.sectorMenu {
  background-color: var(--neutral-white);
  overflow: hidden;
}
.dropdownMenuContents {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--neutral-200);
  box-sizing: border-box;
  width: 202px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.sectorMenu {
  position: relative;
  width: 194px;
  height: 186px;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-source-sans-pro);
}
