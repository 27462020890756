.checkIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.photo {
  position: relative;
  font-weight: 600;
}

.label,
.placeholder {
  position: relative;
}
.placeholder {
  flex: 1;
  line-height: 24px;
}
.input {
  align-self: stretch;
  border-radius: var(--br-7xl);
  border: 1px solid var(--color-silver-100);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-5xl) var(--padding-3xs) 16px;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-gray-100);
}


.content {
  position: absolute;
  top: 215px;
  left: 379px;
  box-shadow: var(--long);
  width: 872px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  text-align: center;
  color: var(--color-yellowgreen-100);
  font-family: var(--font-source-sans-pro);
  flex-direction: column;
}
.universalsidebar1 {
  position: absolute;
  height: 95.45%;
  width: 102.38%;
  top: 0;
  right: -5.95%;
  bottom: 4.55%;
  left: 3.57%;
  background-color: var(--neutral-white);
}
.homeIcon,
.homeIcon1,
.logoIcon {
  position: absolute;
  height: 3.89%;
  width: 28.57%;
  top: 11.99%;
  right: 29.76%;
  bottom: 84.12%;
  left: 41.67%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.homeIcon1,
.logoIcon {
  height: 6.95%;
  width: 70.24%;
  top: 1.01%;
  right: 16.67%;
  bottom: 92.04%;
  left: 13.1%;
}
.homeIcon1 {
  height: 4.04%;
  width: 28.57%;
  top: 15.82%;
  right: 30.95%;
  bottom: 80.13%;
  left: 40.48%;
  cursor: pointer;
}
.projectsIcon {
  top: 22.05%;
  right: 10.71%;
  bottom: 67.85%;
  left: 17.86%;
  cursor: pointer;
}
.analysisIcon,
.projectsIcon,
.settingsIcon,
.usersIcon {
  position: absolute;
  height: 10.1%;
  width: 71.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.usersIcon {
  top: 30.98%;
  right: 9.52%;
  bottom: 58.92%;
  left: 19.05%;
  cursor: pointer;
}
.analysisIcon,
.settingsIcon {
  right: 10.71%;
  left: 17.86%;
}
.analysisIcon {
  top: 41.08%;
  bottom: 48.82%;
  cursor: pointer;
}
.settingsIcon {
  top: 53.37%;
  bottom: 36.53%;
}
.universalsidebar {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-21xl);
  width: 84px;
  height: 594px;
}
.topBarText {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: var(--font-size-13xl);
}
.topBarChild {
  position: absolute;
  top: 25px;
  left: 860px;
  border-radius: var(--br-5xs);
  background-color: var(--color-ghostwhite);
  border: 1px solid var(--color-aliceblue-100);
  box-sizing: border-box;
  width: 169px;
  height: 40px;
}
.vectorIcon {
  position: absolute;
  height: 17.99%;
  width: 1.44%;
  top: 33.65%;
  right: 30.91%;
  bottom: 48.36%;
  left: 67.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.search {
  position: absolute;
  top: 35px;
  left: 910px;
  color: var(--color-darkgray);
}
.topBarItem {
  position: absolute;
  top: 23px;
  left: 1089px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.loggedUser {
  position: absolute;
  top: 34px;
  left: 1136px;
  font-family: var(--font-source-sans-pro);
  color: var(--color-black);
}
.topBar,
.vectorIcon1 {
  position: absolute;
  overflow: hidden;
}
.vectorIcon1 {
  height: 6.85%;
  width: 0.88%;
  top: 38.74%;
  right: 1.41%;
  bottom: 54.41%;
  left: 97.71%;
  max-width: 100%;
  max-height: 100%;
}
.topBar {
  top: 4px;
  left: 122px;
  border-radius: var(--br-11xs);
  background-color: var(--neutral-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 1295px;
  height: 104px;
}
.topBarText1 {
  position: absolute;
  top: 365px;
  left: -1024px;
  font-size: var(--font-size-13xl);
}
.addUser1 {
  position: relative;
  background-color: var(--neutral-white);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
  font-family: var(--font-inter);
}
.formContainer {
  display: flex;
  position: absolute;
  top: 215px;
  left: 379px;
  box-shadow: var(--long);
  width: 500px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  text-align: center;
  color: var(--color-yellowgreen-100);
  font-family: var(--font-source-sans-pro);
  flex-direction: column;
}

.formLabel {
  margin: 10px 0;
}

.formLabel, .formInput {
  width: 100%; /* make each label and input take up the full width of the form */
}

.formInput {
  /* width: 200px; */ /* Commented out this line */
  height: 30px;
  padding: 5px;
  margin-bottom: 10px;
}

.addButton, .backButton, .clearButton {
  width: 100px;
  height: 30px;
  background-color: #a2c73b;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 10px 30px;
  cursor: pointer;
}

.addButton:hover, .backButton:hover, .clearButton:hover {
  background-color: #8ab029;
}
.fieldContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px; /* adjust this value to increase or decrease spacing */
}

.formLabel, .formInput {
  width: 45%; /* adjust this value to change the width of the label and input */
}
.formInput {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}