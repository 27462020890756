.coreAnalysisEfficiency {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.topBar {
    width: 100%;
    height: 60px;
    background-color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.sidebar {
    width: 80px;
    height: 100vh;
    background-color: #444;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentArea {
    flex-grow: 1;
    padding: 20px;
}

.search {
    background-color: #555;
    padding: 5px 10px;
    border-radius: 5px;
}

.userName {
    color: #fff;
    margin-left: 10px;
}

.logoIcon, .vectorIcon, .userIcon {
    margin: 10px;
}

/* Add more styles as per your design and requirements */
